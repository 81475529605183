












































import DetailCard from "./DetailCard.vue";
import Vue, { PropType } from "vue";
import { IViewDetailsMachines } from "@iot-uta-devices/iot-uta-devices-interfaces";
import { getMachineUsers } from "@/api/MachinesAPI";
import UserView from "@/types/UserView";

export default Vue.extend({
  name: "MachineUsersCard",
  components: { DetailCard },
  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
  },
  data: () => ({
    dataTableHeight: 200,
    users: [] as Array<UserView>,
    loading: true,
    error: "",
    headers: [
      { text: "Username", align: "start", value: "username" },
      { text: "Descrizione", align: "start", value: "description" },
    ],
  }),

  watch: {
    user: function () {
      this.initializeData();
    },
  },

  mounted: function () {
    this.initializeData();
  },

  methods: {
    initializeData() {
      this.loading = true;
      this.error = "";
      getMachineUsers(this.machine.machine.id)
        .then((response) => {
          this.users = response.map((user) => ({
            username: user.username,
          description: user.description || "Non disponibile",
          machines_viewer: user.roles.machines_viewer,
          machines_programmer: user.roles.machines_programmer,
          machines_admin: user.roles.machines_admin,
          users_viewer: user.roles.users_viewer,
          users_admin: user.roles.users_admin,
          status: user.status,
          }));
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
