































































import Vue from "vue";
import DetailCard from "./DetailCard.vue";
import { EndButtonType } from "@/types/Client";
import { getEvents } from "@/api/EventsAPI";
import { getSortByMap } from "@/utils/PaginationHelper";
import { EventInfo } from "@/types/EventInfo";

export default Vue.extend({
  name: "MachineEventSection",
  components: { DetailCard },

  props: {
    value: {
      type: Number,
      required: true,
    },
    canProgramMachine: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    dataTableHeight: 0,
    selectedItem: null as EventInfo | null,
    error: "",
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["date"],
      sortDesc: [true]
    },
    footerOptions: {
      itemsPerPageOptions: [25, 50, 100],
      itemsPerPageText: "Righe per pagina",
      pageText: "{0}-{1} di {2}"
    },
    totalEvents: 0,
    events: [] as Array<EventInfo>,
      
    headers: [
      {
        text: "Data e ora",
        align: "start",
        value: "date",
      },
      {
        text: "Urgenza",
        align: "start",
        value: "severity",
      },
      {
        text: "Categoria",
        align: "start",
        value: "category",
      },
      {
        text: "Messaggio",
        align: "start",
        value: "message",
      },
      {
        text: "Potenza WI FI",
        align: "start",
        value: "wifi_power",
      },
      {
        text: "Temperatura",
        align: "start",
        value: "temperature",
      },
      {
        text: "Pressione",
        align: "start",
        value: "pressure",
      },
    ],

    endButtons: [] as Array<EndButtonType>,
  }),

  mounted() {
    this.endButtons = [
      {
        icon: "fas fa-chevron-left",
        text: "Tutti gli eventi",
        disabled: true,
        action: () => {
          /* */
        },
      },
      {
        icon: "fas fa-chevron-right",
        text: "Telemetria",
        action: () => {
          this.$emit("input", 1);
        },
      },
    ];
  },
  watch: {
      options: {
        handler () {
          this.loadEvents()
        },
        deep: true,
      },
    },
  methods: {
    loadEvents(): void {
      this.error = "";
      if (!this.canProgramMachine) {
        this.loading = false;
      } else {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        getEvents(this.$route.params.machineID, itemsPerPage, itemsPerPage*(page-1), getSortByMap(sortBy[0]), sortDesc[0] === true ? "desc" : "asc")
          .then((response) => {
            this.totalEvents = response.totalEvents;
            this.events = response.events.map((ev) => {
                const genericEvent = ev as any;
                return {
                    date: new Date(genericEvent.publish_time),
                    severity: genericEvent.severity ?? "N/A",
                    category: genericEvent.category ?? "N/A",
                    scheduling_id: genericEvent.taskId,
                    message: genericEvent.message,
                    wifi_power: `${genericEvent.wifi} dB`,
                    temperature: `${!isNaN(parseInt(genericEvent.telemetry.temperature, 10)) ? genericEvent.telemetry.temperature + '°C' : 'Nessuna informazione'}`,
                    pressure: `${!isNaN(parseInt(genericEvent.telemetry.pressure, 10)) ? genericEvent.telemetry.pressure + ' bar' : 'Nessuna informazione'}`,
                  };
            });
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateDataTableHeight(): void {
      this.dataTableHeight = window.innerHeight - 345;
    },

    openDetailDialog(item: EventInfo) {
      this.selectedItem = item;
      //@ts-expect-error wrong type
      this.$refs.detailDialog.activateDialog();
    },
  },
});
