










































































































































import Vue, { PropType } from "vue";
import UneditableTextField from "@/globalComponents/UneditableTextField.vue";
import { IViewDetailsMachines } from "@iot-uta-devices/iot-uta-devices-interfaces";
import { putMachineMetaData } from "@/api/MachinesAPI";
import { extend, setInteractionMode } from "vee-validate";
import { min, required } from "vee-validate/dist/rules";
import {
  getDateFromIot,
  isDateDifferenceExcessive,
} from "@/utils/DateHandlers";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto.",
});

extend("min", {
  ...min,
  message: "{_field_} deve contenere almeno {length} caratteri.",
});

export default Vue.extend({
  name: "MachineDetailsSection",
  components: { UneditableTextField },

  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
    canEditMachine: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    client_name: "",
    client_address: "",
    lastOnline: null as Date | null,
    lastState: null as Date | null,
  }),

  mounted() {
    this.initializeData();
  },

  watch: {
    machine: function () {
      this.initializeData();
    },
  },

  methods: {
    initializeData: function () {
      this.client_name = this.machine.machine.client_name || "";
      this.client_address = this.machine.machine.client_address || "";

      if(typeof this.machine.iot.lastHeartbeatTime === "string" && typeof this.machine.iot.lastEventTime === "string" && typeof this.machine.iot.lastStateTime === "string"){
        this.lastOnline = new Date(Math.max(new Date(this.machine.iot.lastHeartbeatTime || 0).getTime(), new Date(this.machine.iot.lastEventTime || 0).getTime()));
        this.lastOnline = this.lastOnline.getTime() === 0 ? null : this.lastOnline;
        this.lastState = new Date(this.machine.iot.lastStateTime || 0);
        this.lastState = this.lastState.getTime() === 0 ? null : this.lastState;
      } else {
        const lastHeartbeatSeconds = this.machine.iot.lastHeartbeatTime?.seconds;
        const lastEventSeconds = this.machine.iot.lastEventTime?.seconds;
  
        const lastStateSeconds = this.machine.iot.lastStateTime?.seconds;
  
        this.lastOnline = getDateFromIot(lastHeartbeatSeconds, lastEventSeconds);
  
        this.lastState = getDateFromIot(lastStateSeconds);
      }
    },

    isDateDifferenceExcessive(
      date: Date | null,
      key: "last_state" | "last_online"
    ): boolean {
      return isDateDifferenceExcessive(date, key);
    },

    getDateErrorColor(key: "last_state" | "last_online"): string {
      if (key === "last_state") {
        return "red";
      } else {
        return "orange";
      }
    },

    submitDetails: async function () {
      // @ts-expect-error wrong type
      const valid = await this.$refs.observer.validate();
      if (
        valid &&
        (this.client_name !== this.machine.machine.client_name ||
          this.client_address !== this.machine.machine.client_address)
      ) {
        putMachineMetaData(this.machine.machine.id, {
          client_name: this.client_name,
          client_address: this.client_address,
        }).finally(() => {
          this.$emit("refetch-machine");
        });
      }
    },
  },
});
