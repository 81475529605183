





































































































































































import Vue, { PropType } from "vue";
import {
  IViewDetailsMachines,
  IViewDetailsScheduler,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
import UneditableTextField from "@/globalComponents/UneditableTextField.vue";
import { ParsedCron, getParsedCron } from "@/utils/CronHandler";
import DeleteProgramDialog from "@/components/DeleteProgramDialog.vue";
import ToggleProgramDialog from "@/components/ToggleProgramDialog.vue";

export default Vue.extend({
  name: "ProgramDetails",
  components: { DeleteProgramDialog, UneditableTextField, ToggleProgramDialog },

  props: {
    program: {
      type: Object as PropType<IViewDetailsScheduler | undefined>,
      required: false,
    },
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
    canEditProgram: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    parsedCron: {
      times: "",
      months: "",
      daysOfTheMonth: "",
      daysOfTheWeek: [],
    } as ParsedCron,
    DAYS_OF_THE_WEEK: [
      { label: "LU", value: 1 },
      { label: "MA", value: 2 },
      { label: "ME", value: 3 },
      { label: "GI", value: 4 },
      { label: "VE", value: 5 },
      { label: "SA", value: 6 },
      { label: "DO", value: 7 },
    ],
  }),

  mounted() {
    if (this.program) {
      this.parsedCron = getParsedCron(this.program.cron);
    }
  },

  watch: {
    program: function (newValue: IViewDetailsScheduler) {
      if (newValue) {
        this.parsedCron = getParsedCron(newValue.cron);
      } else {
        this.parsedCron = {
          times: "",
          months: "",
          daysOfTheMonth: "",
          daysOfTheWeek: [],
        };
      }
    },
  },

  methods: {
    filterItems: function (
      items: Array<{ readonly status: number; readonly label: string }>
    ) {
      return items
        .map((item, index) => ({ ...item, index: index + 1 }))
        .filter((item) => item.status);
    },

    openDeleteProgramDialog: function (): void {
      //@ts-expect-error wrong type
      this.$refs.deleteProgramDialog.activateDialog();
    },

    openToggleProgramDialog: function (): void {
      //@ts-expect-error wrong type
      this.$refs.toggleProgramDialog.activateDialog();
    },
  },
});
