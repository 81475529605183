import { render, staticRenderFns } from "./MachineErrorResolutionSection.vue?vue&type=template&id=9c8f1256&scoped=true&"
import script from "./MachineErrorResolutionSection.vue?vue&type=script&lang=ts&"
export * from "./MachineErrorResolutionSection.vue?vue&type=script&lang=ts&"
import style0 from "./MachineErrorResolutionSection.vue?vue&type=style&index=0&id=9c8f1256&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c8f1256",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VDataTable,VSkeletonLoader})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
