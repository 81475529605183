














































































































































import Vue from "vue";
import ComponentsCardContainer from "@/components/ComponentsCardContainer.vue";
import { IViewDetailsMachines } from "@iot-uta-devices/iot-uta-devices-interfaces";
import {
  hasMachineAdminPermission,
  hasMachineProgrammingPermission,
  hasUserViewPermission,
} from "@/utils/UserPermissions";
import { getMachine } from "@/api/MachinesAPI";
import {
  activateOrDeactivateScheduling,
  postCommand,
} from "@/api/SchedulersAPI";
import ActionButton from "@/globalComponents/ActionButton.vue";
import MachineDetailsCard from "@/components/MachineDetailsCard.vue";
import MachineEventsCard from "@/components/MachineEventsCard.vue";
import MachineProgrammingCard from "@/components/MachineProgrammingCard.vue";
import MachineUsersCard from "@/components/MachineUsersCard.vue";
import SendCommandDialog from "@/components/SendCommandDialog.vue";
import DeleteMachineDialog from "@/components/DeleteMachineDialog.vue";

export default Vue.extend({
  name: "MachineDetails",
  components: {
    DeleteMachineDialog,
    SendCommandDialog,
    MachineProgrammingCard,
    MachineEventsCard,
    MachineDetailsCard,
    MachineUsersCard,
    ActionButton,
    ComponentsCardContainer,
  },

  data: () => ({
    machine: null as unknown as IViewDetailsMachines,
    currentTab: 0,
    loading: true,
    error: "",
    reloadKey: 0,
    activateOrDeactivateSchedulingLoading: false,
    stopLoading: false,
  }),
  created() {
    // @ts-expect-error ts(2339)
    this.$bus.$on("update-detail-card", () => {
      this.reloadKey++;
    });
  },
  beforeDestroy() {
    // @ts-expect-error ts(2339)
    this.$bus.$off();
  },
  mounted: function () {
    this.loading = true;
    this.fetchMachine();
  },

  methods: {
    canEditMachine(): boolean {
      return hasMachineAdminPermission(this.$store.getters.userRoles);
    },

    canProgramMachine(): boolean {
      return hasMachineProgrammingPermission(this.$store.getters.userRoles);
    },

    canViewUsers(): boolean {
      return hasUserViewPermission(this.$store.getters.userRoles);
    },

    fetchMachine(): void {
      this.error = "";
      getMachine(this.$route.params.machineID)
        .then((response: IViewDetailsMachines) => {
          this.machine = response;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleActivateSendCommandDialog(): void {
      //@ts-expect-error wrong type
      this.$refs.sendCommandDialog.activateDialog();
    },

    handleActivateDeleteMachineDialog(): void {
      //@ts-expect-error wrong type
      this.$refs.deleteMachineDialog.activateDialog();
    },

    handleActivateOrDeactivateScheduling(
      action: "activate" | "deactivate" | ""
    ): void {
      if (action) {
        this.activateOrDeactivateSchedulingLoading = true;
        activateOrDeactivateScheduling(this.$route.params.machineID, action)
          .then(() => {
            this.fetchMachine();
          })
          .catch(() => {
            // Do nothing
          })
          .finally(() => {
            this.activateOrDeactivateSchedulingLoading = false;
          });
      }
    },

    handleStopMachine(): void {
      this.stopLoading = true;
      postCommand(this.$route.params.machineID, {
        cmd: 0,
        duration: 0,
        injector: false,
        valve: false,
      }).finally(() => {
        this.stopLoading = false;
      });
    },
  },

  computed: {
    getPlayPauseButtonIcon: function (): string {
      switch (this.machine.machine.activeScheduler) {
        case "pause":
          return "far fa-play-circle";
        case "play":
          return "far fa-pause-circle";
        default:
          return "";
      }
    },

    getPlayPauseButtonText: function (): string {
      switch (this.machine.machine.activeScheduler) {
        case "pause":
          return "Attiva programmazione";
        case "play":
          return "Disattiva programmazione";
        default:
          return "";
      }
    },

    getPlayPauseButtonColor: function (): string | undefined {
      switch (this.machine.machine.activeScheduler) {
        case "pause":
          return "success";
        case "play":
          return "error";
        default:
          return undefined;
      }
    },

    getActionType: function (): "activate" | "deactivate" | "" {
      switch (this.machine.machine.activeScheduler) {
        case "pause":
          return "activate";
        case "play":
          return "deactivate";
        default:
          return "";
      }
    },
  },
});
