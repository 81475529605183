




































import Vue from "vue";
import { deleteMachine } from "@/api/MachinesAPI";

export default Vue.extend({
  name: "DeleteMachineDialog",

  data: () => ({
    loading: false,
    isOpen: false,
    dialogNumber: 0 as 0 | 1,
  }),

  methods: {
    activateDialog: function () {
      this.dialogNumber = 0;
      this.isOpen = true;
    },

    close: function () {
      this.isOpen = false;
      this.dialogNumber = 0;
    },

    goToNextDialog: function () {
      this.dialogNumber = 1;
    },

    deleteMachineConfirm(): void {
      this.loading = true;
      deleteMachine(this.$route.params.machineID)
        .then(() => {
          this.close();
          this.$router.replace("/machines");
        })
        .catch(() => {
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
