import {
  Command_1,
  Command_2,
  Commands,
  ICommand,
  ICreateScheduler,
  IViewListScheduler,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
import axios, { AxiosError, AxiosResponse } from "axios";
import { errorsHandler } from "@/api/utils/ErrorsHandler";
import store from "@/store";

const BASE_URL = process.env.VUE_APP_SERVER_BASE_URL;

export const getSchedulers = (machineID: string): Promise<IViewListScheduler> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines/${machineID}/scheduler`)
      .then(
        (response: AxiosResponse<{ readonly data: IViewListScheduler }>) => {
          resolve(response.data.data);
        }
      )
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

type BasicResponse = { readonly data: string; readonly message: string };

export const postScheduler = (
  machineID: string,
  payload: ICreateScheduler<Command_1 | Command_2>
): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/machines/${machineID}/scheduler`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });

export const deleteScheduler = (
  machineId: string,
  schedulerId: string
): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${BASE_URL}/machines/${machineId}/scheduler/${schedulerId}`)
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });

export const activateOrDeactivateScheduling = (
  machineId: string,
  request: "activate" | "deactivate"
): Promise<void> => {
  let method: "PUT" | "PATCH";

  switch (request) {
    case "activate":
      method = "PATCH";
      break;
    case "deactivate":
      method = "PUT";
      break;
    default:
      store.commit("mutation_open_snackbar_error", "Invalid command");
      throw new Error();
  }
  return new Promise((resolve, reject) => {
    axios
      .request({
        url: `${BASE_URL}/machines/${machineId}/scheduler`,
        method: method,
      })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve();
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });
};

export const toggleScheduler = (
  machineId: string,
  schedulerId: string,
  status: "pause" | "resume"
): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${BASE_URL}/machines/${machineId}/scheduler/${schedulerId}/${status}`)
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve(response.data.data);
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });

export const postCommand = (
  machineID: string,
  payload: ICommand<Commands>
): Promise<void> =>
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/machines/${machineID}/commands`, { data: payload })
      .then((response: AxiosResponse<BasicResponse>) => {
        resolve();
        store.commit("mutation_open_snackbar_success", response.data.message);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          if (errorMessage.message) {
            errorMessage = errorMessage.message;
          }
          errorsHandler(error);
          store.commit("mutation_open_snackbar_error", errorMessage);
        } else {
          store.commit("mutation_open_snackbar_error", error);
        }
        reject();
      });
  });
