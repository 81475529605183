








































import Vue, { PropType } from "vue";
import { TelemetryInfo } from "@/types/TelemetryInfo";
import UneditableTextField from "@/globalComponents/UneditableTextField.vue";

export default Vue.extend({
  name: "EventTelemetryDialog",
  components: { UneditableTextField },
  props: {
    telemetry: {
      type: Object as PropType<TelemetryInfo>,
      required: false,
      default: null,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },

    close: function () {
      this.isOpen = false;
    },
  },
});
