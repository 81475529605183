



















































import Vue, { PropType } from "vue";
import DetailCard from "./DetailCard.vue";
import {
  IViewDetailsMachines,
  IViewListScheduler,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
import { getSchedulers } from "@/api/SchedulersAPI";
import ProgramsList from "@/components/ProgramsList.vue";
import ProgramDetails from "@/components/ProgramDetails.vue";
import { EndButtonType } from "@/types/Client";
import AddProgramDialog from "@/components/AddProgramDialog.vue";

export default Vue.extend({
  name: "MachineProgrammingCard",
  components: { AddProgramDialog, ProgramDetails, ProgramsList, DetailCard },

  data: () => ({
    loading: true,
    error: "",
    schedulers: [] as IViewListScheduler,
    currentProgramId: null as string | null,
    endButtons: [] as Array<EndButtonType>,
  }),

  mounted() {
    this.initializeData();
    this.currentProgramId = this.schedulers[0]?.id || "0";
    this.endButtons = [
      {
        text: "Aggiungi programmazione",
        icon: "fas fa-plus",
        action: () => {
          // @ts-expect-error wrong type
          this.$refs.addProgramDialog.activateDialog();
        },
        disabled: !this.$props.canProgramMachine,
      },
    ];
  },

  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
    canProgramMachine: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    initializeData: function (idToSelect?: string) {
      this.error = "";
      getSchedulers(this.$route.params.machineID)
        .then((response) => {
          this.schedulers = response.sort((a, b) =>
            a.description.toLowerCase() < b.description.toLowerCase() ? -1 : 1
          );
          if (idToSelect) {
            this.currentProgramId = idToSelect;
          } else {
            this.currentProgramId = this.schedulers[0]?.id;
          }
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initializeDataOnDeletion: function (deletedId: string) {
      const currentSchedulers = this.schedulers;
      const currentIndex = currentSchedulers.findIndex(
        (s) => s.id === deletedId
      );
      if (currentIndex === -1) {
        this.initializeData();
      } else if (currentIndex + 1 < currentSchedulers.length) {
        this.initializeData(currentSchedulers[currentIndex + 1].id);
      } else if (currentIndex - 1 >= 0) {
        this.initializeData(currentSchedulers[currentIndex - 1].id);
      } else {
        this.initializeData();
      }
    },

    getSelectedProgram: function () {
      return this.schedulers.find(
        (scheduler) => scheduler.id === this.currentProgramId
      );
    },
  },
});
