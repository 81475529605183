













































import Vue, { PropType } from "vue";
import { ErrorInfo } from "@/types/ErrorInfo";
import UneditableTextField from "@/globalComponents/UneditableTextField.vue";

export default Vue.extend({
  name: "EventErrorDialog",
  components: { UneditableTextField },
  props: {
    title: {
      type: String,
      required: true,
    },
    error: {
      type: Object as PropType<ErrorInfo>,
      required: false,
      default: null,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },

    close: function () {
      this.isOpen = false;
    },
  },
});
