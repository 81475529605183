





















import Vue, { PropType } from "vue";
import {
  Command_1,
  Command_2,
  IViewDetailsMachines,
  Valve,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
export default Vue.extend({
  name: "SelectValveRadioGroup",

  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    command: {
      type: Number as PropType<Command_1 | Command_2>,
      required: true,
    },
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
  },

  watch: {
    isDialogOpen: function (newValue) {
      if (newValue) {
        this.initializeData();
      }
    },
    value: function (newValue) {
      this.selectedValve = newValue;
    },
    selectedValve: function (newValue) {
      this.$emit("input", newValue);
    },
  },

  data: () => ({
    selectedValve: 0,
    availableValves: [] as { index: number; label: string }[],
  }),

  mounted() {
    this.initializeData();
  },

  methods: {
    initializeData: function () {
      this.availableValves = this.$props.machine.machine.hardware.valves
        .map((item: Valve, index: number) => ({
          ...item,
          index: index + 1,
        }))
        .filter(
          (item: Valve & { index: number }) => item.status && item.index !== 1
        );
      if (this.availableValves.length) {
        this.selectedValve = this.availableValves[0].index;
      }
    },
  },
});
