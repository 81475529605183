var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitDetails.apply(null, arguments)}}},[_c('validation-observer',{ref:"observer"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('uneditable-text-field',{staticClass:"mr-5",attrs:{"label":"ID Macchina","value":_vm.machine.machine.id,"disabled":!_vm.canEditMachine}})],1),_c('v-col',[_c('uneditable-text-field',{staticClass:"mx-5",attrs:{"label":"Classe","value":_vm.machine.machine.class,"disabled":!_vm.canEditMachine}})],1),_c('v-col',[_c('uneditable-text-field',{staticClass:"mx-5",attrs:{"label":"Numero serie","value":_vm.machine.machine.serial_number,"disabled":!_vm.canEditMachine}})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"Nome Cliente","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-form',{attrs:{"disabled":!_vm.canEditMachine},on:{"submit":function($event){$event.preventDefault();return _vm.submitDetails.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"ml-5",attrs:{"error-messages":errors,"label":"Nome cliente","type":"text","autocomplete":"false"},on:{"blur":_vm.submitDetails},model:{value:(_vm.client_name),callback:function ($$v) {_vm.client_name=$$v},expression:"client_name"}})],1)]}}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('uneditable-text-field',{staticClass:"mr-5",attrs:{"label":"NUM_ID","value":_vm.machine.machine.numId,"disabled":!_vm.canEditMachine}})],1),_c('v-col',[_c('uneditable-text-field',{staticClass:"mx-5",attrs:{"label":"Modello","value":_vm.machine.machine.model,"disabled":!_vm.canEditMachine}})],1),_c('v-col',[_c('uneditable-text-field',{staticClass:"mx-5",attrs:{"label":"Codice SGD","value":_vm.machine.machine.device_code,"disabled":!_vm.canEditMachine}})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"Descrizione Cliente","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-form',{attrs:{"disabled":!_vm.canEditMachine},on:{"submit":function($event){$event.preventDefault();return _vm.submitDetails.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"ml-5",attrs:{"error-messages":errors,"label":"Indirizzo cliente","type":"text","autocomplete":"false"},on:{"blur":_vm.submitDetails},model:{value:(_vm.client_address),callback:function ($$v) {_vm.client_address=$$v},expression:"client_address"}})],1)]}}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('uneditable-text-field',{staticClass:"mr-5",attrs:{"label":"Ultima volta online","value":_vm._f("normalizeDate")(_vm.lastOnline),"disabled":!_vm.canEditMachine,"end-icon":_vm.isDateDifferenceExcessive(_vm.lastOnline, 'last_online')
              ? 'fas fa-exclamation-triangle'
              : undefined,"color":_vm.isDateDifferenceExcessive(_vm.lastOnline, 'last_online')
              ? _vm.getDateErrorColor('last_online')
              : ''}})],1),_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('uneditable-text-field',{staticClass:"mx-5",attrs:{"label":"Data ultimo stato","value":_vm._f("normalizeDate")(_vm.lastState),"disabled":!_vm.canEditMachine,"end-icon":_vm.isDateDifferenceExcessive(_vm.lastState, 'last_state')
              ? 'fas fa-exclamation-triangle'
              : undefined,"color":_vm.isDateDifferenceExcessive(_vm.lastState, 'last_state')
              ? _vm.getDateErrorColor('last_state')
              : ''}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }