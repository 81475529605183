var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",attrs:{"slim":""}},[_c('v-layout',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"details",attrs:{"cols":"4"}},[_vm._v(" Configurazione Soglie "),_c('br'),_c('v-btn',{staticClass:"mt-16",staticStyle:{"padding":"0"},attrs:{"disabled":!_vm.canEditMachine || _vm.isFormInvalid() || _vm.loading,"text":"","loading":_vm.loading,"color":"primary"},on:{"click":_vm.submitConfiguration}},[_c('v-icon',{staticClass:"mr-5",attrs:{"small":""}},[_vm._v(" fas fa-redo ")]),_vm._v(" Aggiorna configurazione ")],1)],1),_c('v-col',{staticClass:"ml-3"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitConfiguration.apply(null, arguments)}}},_vm._l((this.data),function(item,index){return _c('v-row',{key:index,attrs:{"no-gutters":""}},[_c('v-col',[_c('uneditable-switch',{attrs:{"label":item.title || '',"disabled":!_vm.canEditMachine,"value":Boolean(item.status)}})],1),_c('v-col',[(item.status)?_c('validation-provider',{attrs:{"name":("Min " + index),"rules":{
                  required: true,
                  min_value: Number.MIN_SAFE_INTEGER,
                  max_value: item.max,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"50%"},attrs:{"label":item.min_label,"disabled":!_vm.canEditMachine,"error-messages":errors,"type":"number","suffix":item.unit,"autocomplete":"false"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitConfiguration.apply(null, arguments)}},model:{value:(item.min),callback:function ($$v) {_vm.$set(item, "min", $$v)},expression:"item.min"}})]}}],null,true)}):_vm._e()],1),_c('v-col',[(item.status)?_c('validation-provider',{attrs:{"name":("Max " + index),"rules":{
                  required: true,
                  min_value: item.min,
                  max_value: Number.MAX_SAFE_INTEGER,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"50%"},attrs:{"label":item.max_label,"disabled":!_vm.canEditMachine,"error-messages":errors,"type":"number","suffix":item.unit,"autocomplete":"false"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitConfiguration.apply(null, arguments)}},model:{value:(item.max),callback:function ($$v) {_vm.$set(item, "max", $$v)},expression:"item.max"}})]}}],null,true)}):_vm._e()],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }