import { render, staticRenderFns } from "./MachineDetails.vue?vue&type=template&id=6da7661a&scoped=true&"
import script from "./MachineDetails.vue?vue&type=script&lang=ts&"
export * from "./MachineDetails.vue?vue&type=script&lang=ts&"
import style0 from "./MachineDetails.vue?vue&type=style&index=0&id=6da7661a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da7661a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAlert,VCol,VContainer,VIcon,VLayout,VRow,VSkeletonLoader,VTab,VTabs})
