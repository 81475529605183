




















































import Vue, { PropType } from "vue";
import { IViewDetailsMachines } from "@iot-uta-devices/iot-uta-devices-interfaces";
import UneditableSwitch from "@/globalComponents/UneditableSwitch.vue";

export default Vue.extend({
  name: "MachineHardwareSection",
  components: { UneditableSwitch },
  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
    canEditMachine: {
      type: Boolean,
      required: true,
    },
  },
});
