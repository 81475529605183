






















































































import Vue, { PropType } from "vue";
import { postCommand } from "@/api/SchedulersAPI";
import {
  Command_1,
  Command_2,
  ICommand,
  IViewDetailsMachines,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
import SelectInjectorRadioGroup from "@/components/SelectInjectorRadioGroup.vue";
import SelectValveRadioGroup from "@/components/SelectValveRadioGroup.vue";

// ToDO: I componenti per selezionare comandi, valvola, iniettore e durata sono replicati da AddProgramDialog. Lo stesso vale per le funzioni in methods per gestirli.
export default Vue.extend({
  name: "SendCommandDialog",
  components: { SelectValveRadioGroup, SelectInjectorRadioGroup },
  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
    loading: false,

    command: 1 as Command_1 | Command_2,
    injector: 0,
    valve: 0,

    duration: 60,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },

    close: function () {
      this.isOpen = false;
      this.command = 1;
      this.injector = 0;
      this.valve = 0;
      this.duration = 60;
    },

    fixDuration: function (event: FocusEvent | KeyboardEvent): void {
      const target = event.target as HTMLFormElement;
      if (target.value < 5) {
        target.value = 5;
        this.duration = target.value;
      } else if (target.value > 240) {
        target.value = 240;
        this.duration = target.value;
      }
    },

    isCommandValid: function (): boolean {
      return (
        this.duration >= 5 &&
        this.duration <= 240 &&
        (this.command === 1 || this.command === 2)
      );
    },

    sendCycleOrCleaningCommand: function () {
      if (this.isCommandValid()) {
        this.loading = true;
        const newCommand: ICommand<Command_1 | Command_2> =
          this.command === 1
            ? {
                cmd: 1,
                valve: this.valve,
                injector: this.injector,
                duration: this.duration,
              }
            : {
                cmd: 2,
                valve: this.valve,
                injector: false,
                duration: this.duration,
              };

        postCommand(this.$route.params.machineID, newCommand)
          .then(() => {
            this.close();
          })
          .catch(() => {
            // do nothing.
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    sendStopCommand: function () {
      this.loading = true;
      postCommand(this.$route.params.machineID, {
        cmd: 0,
        duration: 0,
        injector: false,
        valve: false,
      })
        .then(() => {
          this.close();
        })
        .catch(() => {
          // do nothing.
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
