
















import Vue, { PropType } from "vue";
import {
  Command_1,
  Command_2,
  Injector,
  IViewDetailsMachines,
} from "@iot-uta-devices/iot-uta-devices-interfaces";
export default Vue.extend({
  name: "SelectInjectorRadioGroup",

  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    command: {
      type: Number as PropType<Command_1 | Command_2>,
      required: true,
    },
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
  },

  watch: {
    isDialogOpen: function (newValue) {
      if (newValue) {
        this.initializeData();
      }
    },
    value: function (newValue) {
      this.selectedInjector = newValue;
    },
    selectedInjector: function (newValue) {
      this.$emit("input", newValue);
    },
    command: function () {
      this.forceInjectorSelection();
    },
  },

  data: () => ({
    selectedInjector: 0,
    availableInjectors: [] as { index: number; label: string }[],
  }),

  mounted() {
    this.initializeData();
  },

  methods: {
    initializeData: function () {
      this.selectedInjector = this.$props.value;
      this.availableInjectors = this.$props.machine.machine.hardware.injectors
        .map((item: Injector, index: number) => ({
          ...item,
          index: index + 1,
        }))
        .filter((item: Injector) => item.status);
      if (this.availableInjectors.length) {
        this.selectedInjector = this.availableInjectors[0].index;
      }
    },

    forceInjectorSelection: function () {
      if (this.command === 2) {
        this.selectedInjector = 0;
      } else if (
        this.command === 1 &&
        this.selectedInjector === 0 &&
        this.availableInjectors.length
      ) {
        this.selectedInjector = this.availableInjectors[0].index;
      }
    },
  },
});
