


























import Vue from "vue";
import { deleteScheduler } from "@/api/SchedulersAPI";

export default Vue.extend({
  name: "DeleteProgramDialog",

  props: {
    programId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
    loading: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },
    close: function () {
      this.isOpen = false;
    },
    deleteProgramConfirm(): void {
      this.loading = true;
      deleteScheduler(this.$route.params.machineID, this.$props.programId)
        .then((id: string) => {
          this.close();
          this.$emit("refetch-programs", id);
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
