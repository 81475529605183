


















































import Vue, { PropType } from "vue";
import { IViewListScheduler } from "@iot-uta-devices/iot-uta-devices-interfaces";

type Program = {
  readonly description: string;
};

export default Vue.extend({
  name: "ProgramsList",

  data: () => ({
    headers: [
      {
        align: "start",
        value: "description",
        sortable: true,
      },
    ],
    programs: [] as Array<Program>,
  }),

  props: {
    schedulers: {
      type: Array as PropType<IViewListScheduler>,
      required: true,
    },
    machineActiveScheduler: {
      type: Boolean,
      required: true,
    },
    canEditMachine: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: String,
      required: false,
    }
  },

  mounted() {
    this.initializeData();
  },

  watch: {
    schedulers: function () {
      this.initializeData();
    },
  },

  methods: {
    initializeData: function () {
      this.programs = this.schedulers.map((scheduler) => {
        return {
          description: scheduler.description,
          id: scheduler.id,
          isActive: scheduler.isActive
        };
      });
    },
  },
});
