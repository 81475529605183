





































































































































































































import Vue, { PropType } from "vue";
import { postScheduler } from "@/api/SchedulersAPI";
import {
  Command_1,
  Command_2,
  IViewDetailsMachines,
} from "@iot-uta-devices/iot-uta-devices-interfaces";

import { extend, setInteractionMode } from "vee-validate";
import { min, required } from "vee-validate/dist/rules";
import SelectInjectorRadioGroup from "@/components/SelectInjectorRadioGroup.vue";
import SelectValveRadioGroup from "@/components/SelectValveRadioGroup.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto.",
});

extend("min", {
  ...min,
  message: "{_field_} deve contenere almeno {length} caratteri.",
});

const ALLOWED_INTERVAL_MINUTES = {
  "Ogni ora": "0",
  "Ogni 5 minuti": "*/5",
  "Ogni 10 minuti": "*/10",
  "Ogni 15 minuti": "*/15",
  "Ogni 20 minuti": "*/20",
  "Ogni 30 minuti": "*/30",
};

const normalizeHour = (value: number): string => {
  return `${value.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:00`;
};

const normalizeMinute = (value: number): string => {
  return value.toLocaleString(undefined, { minimumIntegerDigits: 2 });
};

// Un oggetto dove le chiavi sono le ore da 0 a 23 in formato "hh:00" e i valori le ore corrispondenti in tipo "number".
const ALLOWED_HOURS_GENERAL: { [key in string]: number } = [
  ...Array(24).keys(),
].reduce((obj, val) => Object.assign({ [normalizeHour(val)]: val }, obj), {});

// Un oggetto dove le chiavi sono le ore da 1 a 24 in formato "hh:00" e i valori le ore in tipo "number" con valore diminuito di uno rispetto alla chiave.
const ALLOWED_HOURS_TO: { [key in string]: number } = [...Array(24).keys()]
  .map((i) => ++i)
  .reduce(
    (obj, val) => Object.assign({ [normalizeHour(val)]: val - 1 }, obj),
    {}
  );

// Un oggetto dove le chiavi sono i minuti da 0 a 55 a passi di 5 in formato "mm" e i valori i minuti corrispondenti in tipo "number"
const ALLOWED_MINUTES_SPECIFIC: { [key in string]: number } = [
  ...Array(60).keys(),
]
  .filter((i) => i % 5 === 0)
  .reduce(
    (obj, val) => Object.assign({ [normalizeMinute(val)]: val }, obj),
    {}
  );

export default Vue.extend({
  name: "AddProgramDialog",
  components: { SelectValveRadioGroup, SelectInjectorRadioGroup },
  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,

    description: "",
    command: 1 as Command_1 | Command_2,
    injector: 0,
    valve: 0,

    days: [] as Array<number>,
    allDaysSelected: false,

    timesSelection: "specific" as "specific" | "intervals",
    specificTimesHours: [] as Array<string>,
    specificHoursMenuOpen: false,
    specificTimesMinutes: [] as Array<string>,
    specificMinutesMenuOpen: false,

    intervalHours: ["", ""] as [
      keyof typeof ALLOWED_HOURS_GENERAL,
      keyof typeof ALLOWED_HOURS_TO
    ],
    intervalMinutes: "" as keyof typeof ALLOWED_INTERVAL_MINUTES,

    duration: 60,

    DAYS_OF_THE_WEEK: [
      { label: "LU", value: 1 },
      { label: "MA", value: 2 },
      { label: "ME", value: 3 },
      { label: "GI", value: 4 },
      { label: "VE", value: 5 },
      { label: "SA", value: 6 },
      { label: "DO", value: 7 },
    ],

    loading: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },

    close: function () {
      this.isOpen = false;
      this.description = "";
      this.command = 1;
      this.injector = 0;
      this.valve = 0;
      this.days = [];
      this.allDaysSelected = false;
      this.timesSelection = "specific";
      this.specificTimesHours = [];
      this.specificTimesMinutes = [];
      this.intervalHours = ["", ""];
      this.intervalMinutes = "" as keyof typeof ALLOWED_INTERVAL_MINUTES;
      this.duration = 60;

      //@ts-expect-error wrong type
      this.$refs.observer.reset();
    },

    removeDay: function (value: number) {
      this.days = this.days.filter((day) => day !== value);
    },

    addDay: function (value: number) {
      if (!this.allDaysSelected && !this.days.find((day) => day === value)) {
        this.days.push(value);
      }
    },

    allowedSpecificHours: function (): Array<string> {
      return Object.keys(ALLOWED_HOURS_GENERAL).sort((a, b) =>
        a < b ? -1 : 1
      );
    },

    allowedSpecificMinutes: function (): Array<string> {
      return Object.keys(ALLOWED_MINUTES_SPECIFIC).sort((a, b) =>
        a < b ? -1 : 1
      );
    },

    sortArray: function (array: Array<string>): Array<string> {
      const sortedArray = [...array].sort((a, b) => (a < b ? -1 : 1));
      return [...array].sort((a, b) => (a < b ? -1 : 1));
    },

    sortSpecificHours(): void {
      this.specificTimesHours = this.specificTimesHours.sort((a, b) =>
        a < b ? -1 : 1
      );
    },

    sortSpecificMinutes(): void {
      this.specificTimesMinutes = this.specificTimesMinutes.sort((a, b) =>
        a < b ? -1 : 1
      );
    },

    allowedHoursFrom: function (): Array<string> {
      if (this.intervalHours[1]) {
        return Object.keys(ALLOWED_HOURS_GENERAL)
          .filter(
            (h: string) =>
              ALLOWED_HOURS_GENERAL[h] <=
              ALLOWED_HOURS_TO[this.intervalHours[1]]
          )
          .sort((a, b) => (a < b ? -1 : 1));
      } else {
        return Object.keys(ALLOWED_HOURS_GENERAL).sort((a, b) =>
          a < b ? -1 : 1
        );
      }
    },

    allowedHoursTo: function (): Array<string> {
      if (this.intervalHours[0]) {
        return Object.keys(ALLOWED_HOURS_TO)
          .filter(
            (h: string) =>
              ALLOWED_HOURS_TO[h] >=
              ALLOWED_HOURS_GENERAL[this.intervalHours[0]]
          )
          .sort((a, b) => (a < b ? -1 : 1));
      } else {
        return Object.keys(ALLOWED_HOURS_TO).sort((a, b) => (a < b ? -1 : 1));
      }
    },

    allowedIntervalMinutes: function (): Array<string> {
      return Object.keys(ALLOWED_INTERVAL_MINUTES);
    },

    fixDuration: function (event: FocusEvent | KeyboardEvent): void {
      const target = event.target as HTMLFormElement;
      if (target.value < 5) {
        target.value = 5;
        this.duration = target.value;
      } else if (target.value > 240) {
        target.value = 240;
        this.duration = target.value;
      }
    },

    isProgramValid: function (): boolean {
      //@ts-expect-error wrong type
      const isDescriptionValid = !this.$refs.observer?.flags?.invalid;

      const isSpecificTimesValid = () =>
        this.timesSelection === "specific" &&
        !!this.specificTimesHours.length &&
        !this.specificTimesHours.filter((h) => !(h in ALLOWED_HOURS_GENERAL))
          .length &&
        !!this.specificTimesMinutes.length &&
        !this.specificTimesMinutes.filter(
          (m) => !(m in ALLOWED_MINUTES_SPECIFIC)
        ).length;

      const isIntervalTimesValid = () =>
        this.timesSelection === "intervals" &&
        Object.keys(ALLOWED_INTERVAL_MINUTES).includes(this.intervalMinutes) &&
        Object.keys(ALLOWED_HOURS_GENERAL).includes(this.intervalHours[0]) &&
        Object.keys(ALLOWED_HOURS_TO).includes(this.intervalHours[1]) &&
        ALLOWED_HOURS_GENERAL[this.intervalHours[0]] <=
          ALLOWED_HOURS_TO[this.intervalHours[1]];

      const isCronValid =
        (!!this.days.length || this.allDaysSelected) &&
        (isSpecificTimesValid() || isIntervalTimesValid());

      return (
        isDescriptionValid &&
        isCronValid &&
        this.duration >= 5 &&
        this.duration <= 240
      );
    },

    addProgram: function () {
      if (this.isProgramValid()) {
        let cron = "";

        const daysCron = this.allDaysSelected
          ? "1,2,3,4,5,6,7"
          : this.days.join(",");

        if (this.timesSelection === "intervals") {
          const minutes = ALLOWED_INTERVAL_MINUTES[this.intervalMinutes];
          const hoursFrom = ALLOWED_HOURS_GENERAL[this.intervalHours[0]];
          const hoursTo = ALLOWED_HOURS_TO[this.intervalHours[1]];

          cron = `${minutes} ${hoursFrom}-${hoursTo} * * ${daysCron}`;
        } else if (this.timesSelection === "specific") {
          const minutes = this.specificTimesMinutes
            .map((m) => ALLOWED_MINUTES_SPECIFIC[m])
            .join(",");
          const hours = this.specificTimesHours
            .map((m) => ALLOWED_HOURS_GENERAL[m])
            .join(",");
          cron = `${minutes} ${hours} * * ${daysCron}`;
        } else {
          return;
        }

        const program = {
          cron,
          description: this.description,
          cmd: this.command,
          duration: this.duration,
          injector:
            this.command === 1 ? this.injector : (false as unknown as false),
          valve: this.valve,
        };

        this.loading = true;
        postScheduler(this.$route.params.machineID, program)
          .then((id: string) => {
            this.$emit("refetch-programs", id);
            this.close();
          })
          .catch(() => {
            // do nothing.
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  filters: {
    normalizeTime: function (hour: string): string {
      return `${hour}:00`;
    },
  },
});
