import axios, { AxiosError, AxiosResponse } from "axios";
import { errorsHandler } from "@/api/utils/ErrorsHandler";
import { IViewPaginatedDetails_Error, IViewPaginatedDetails_Event, IViewPaginatedDetails_Resolution, IViewPaginatedDetails_Telemetry } from "@iot-uta-devices/iot-uta-devices-interfaces/dist/interfaces/api/events/IAPI";

const BASE_URL = process.env.VUE_APP_SERVER_BASE_URL;
interface PaginationQueryParams {
  limit: number
  offset: number
  orderBy: string
  orderByDirection: string
};

export const getEvents = (machineID: string, limit: number, offset: number, orderBy: string, orderByDirection: string): Promise<IViewPaginatedDetails_Event> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines/${machineID}/events`, { params: getPaginationQueryParams(limit, offset, orderBy, orderByDirection) })
      .then(
        (response: AxiosResponse<{ readonly data: IViewPaginatedDetails_Event }>) => {
          resolve(response.data.data);
        }
      )
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

export const getTelemetry = (machineID: string, limit: number, offset: number, orderBy: string, orderByDirection: string): Promise<IViewPaginatedDetails_Telemetry> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines/${machineID}/events/telemetry`, { params: getPaginationQueryParams(limit, offset, orderBy, orderByDirection) })
      .then(
        (response: AxiosResponse<{ readonly data: IViewPaginatedDetails_Telemetry }>) => {
          resolve(response.data.data);
        }
      )
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

export const getErrors = (machineID: string, limit: number, offset: number, orderBy: string, orderByDirection: string): Promise<IViewPaginatedDetails_Error> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines/${machineID}/events/error`, { params: getPaginationQueryParams(limit, offset, orderBy, orderByDirection) })
      .then((response: AxiosResponse<{ readonly data: IViewPaginatedDetails_Error }>) => {
        resolve(response.data.data);
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

export const getErrorResolutions = (machineID: string, limit: number, offset: number, orderBy: string, orderByDirection: string): Promise<IViewPaginatedDetails_Resolution> =>
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/machines/${machineID}/events/resolution`, { params: getPaginationQueryParams(limit, offset, orderBy, orderByDirection) })
      .then(
        (response: AxiosResponse<{ readonly data: IViewPaginatedDetails_Resolution }>) => {
          resolve(response.data.data);
        }
      )
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response) {
          errorMessage = error.response.data?.message || "Errore sconosciuto";
          errorsHandler(error);
          reject(new Error(errorMessage));
        } else {
          reject(error);
        }
      });
  });

function getPaginationQueryParams(limit: number, offset: number, orderBy: string, orderByDirection: string): PaginationQueryParams {
  return {
    limit: limit,
    offset: offset,
    orderBy: orderBy,
    orderByDirection: orderByDirection
  };
}