




















import Vue from "vue";
import UneditableTextField from "./UneditableTextField.vue";

export default Vue.extend({
  name: "UneditableSwitch",
  components: { UneditableTextField },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isLabelTextField: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
});
