






































import Vue, { PropType } from "vue";
import DetailCard from "./DetailCard.vue";
import { IViewDetailsMachines } from "@iot-uta-devices/iot-uta-devices-interfaces";
import MachineDetailsSection from "@/components/MachineDetailsSection.vue";
import MachineHardwareSection from "@/components/MachineHardwareSection.vue";
import MachineConfigurationSection from "@/components/MachineConfigurationSection.vue";

export default Vue.extend({
  name: "MachineDetailsCard",
  components: {
    MachineConfigurationSection,
    MachineHardwareSection,
    MachineDetailsSection,
    DetailCard,
  },

  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
    canEditMachine: {
      type: Boolean,
      required: true,
    },
    canProgramMachine: {
      type: Boolean,
      required: true,
    },
  },
});
