


























import Vue from "vue";
import { toggleScheduler } from "@/api/SchedulersAPI";

export default Vue.extend({
  name: "ToggleProgramDialog",

  props: {
    programId: {
      type: String,
      required: true,
    },
    programIsActive: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
    loading: false,
  }),

  methods: {
    activateDialog: function () {
      this.isOpen = true;
    },
    close: function () {
      this.isOpen = false;
    },
    toggleProgramConfirm(): void {
      this.loading = true;
      toggleScheduler(this.$route.params.machineID, this.$props.programId, this.$props.programIsActive ? "pause" : "resume")
        .then((id: string) => {
          this.close();
          this.$emit("refetch-program", id);
        })
        .catch(() => {
          // Do nothing
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
