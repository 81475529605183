
































































import Vue from "vue";
import DetailCard from "./DetailCard.vue";
import { EndButtonType } from "@/types/Client";
import { getTelemetry as getTelemetry } from "@/api/EventsAPI";
import { TelemetryInfo } from "@/types/TelemetryInfo";
import EventTelemetryDialog from "@/components/EventTelemetryDialog.vue";
import { getSortByMap } from "@/utils/PaginationHelper";

export default Vue.extend({
  name: "MachineTelemetrySection",
  components: { EventTelemetryDialog, DetailCard },

  props: {
    value: {
      type: Number,
      required: true,
    },
    canProgramMachine: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    dataTableHeight: 0,
    selectedItem: null as TelemetryInfo | null,
    error: "",
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["date"],
      sortDesc: [true]
    },
    footerOptions: {
      itemsPerPageOptions: [25, 50, 100],
      itemsPerPageText: "Righe per pagina",
      pageText: "{0}-{1} di {2}"
    },
    totalEvents: 0,
    telemetryEvents: [] as Array<TelemetryInfo>,
      
    headers: [
      {
        text: "Data e ora",
        align: "start",
        value: "date",
      },
      {
        text: "Messaggio",
        align: "start",
        value: "message",
      },
      {
        text: "Potenza WI FI",
        align: "start",
        value: "wifi_power",
      },
      {
        text: "Temperatura",
        align: "start",
        value: "temperature",
      },
      {
        text: "Pressione",
        align: "start",
        value: "pressure",
      },
    ],

    endButtons: [] as Array<EndButtonType>,
  }),

  mounted() {
    this.endButtons = [
      {
        icon: "fas fa-chevron-left",
        text: "Tutti gli eventi",
        action: () => {
          this.$emit("input", 0);
        },
      },
      {
        icon: "fas fa-chevron-right",
        text: "Errori",
        action: () => {
          this.$emit("input", 2);
        },
      },
    ];
  },
  watch: {
      options: {
        handler () {
          this.loadTelemetryEvents()
        },
        deep: true,
      },
    },
  methods: {
    loadTelemetryEvents(): void {
      this.error = "";
      if (!this.canProgramMachine) {
        this.loading = false;
      } else {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        getTelemetry(this.$route.params.machineID, itemsPerPage, itemsPerPage*(page-1), getSortByMap(sortBy[0]), sortDesc[0] === true ? "desc" : "asc")
          .then((response) => {
            this.totalEvents = response.totalEvents;
            this.telemetryEvents = response.events.map((tel) => ({
              date: new Date(tel.publish_time),
              scheduling_id: tel.taskId,
              message: tel.message,
              wifi_power: `${tel.wifi} dB`,
              temperature: `${!isNaN(parseInt(tel.telemetry.temperature, 10)) ? tel.telemetry.temperature + ' °C' : 'Nessuna informazione'} `,
              pressure: `${!isNaN(parseInt(tel.telemetry.pressure, 10)) ? tel.telemetry.pressure + ' bar' : 'Nessuna informazione'}`,
            }));
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateDataTableHeight(): void {
      this.dataTableHeight = window.innerHeight - 345;
    },

    openDetailDialog(item: TelemetryInfo) {
      this.selectedItem = item;
      //@ts-expect-error wrong type
      this.$refs.detailDialog.activateDialog();
    },
  },
});
