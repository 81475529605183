var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('detail-card',{attrs:{"title":"Lista programmazioni macchina","icon":"fas fa-cog","end-buttons":this.endButtons}},[_c('v-row',{staticClass:"fill-height pb-16"},[_c('v-col',{attrs:{"cols":"4"}},[_c('programs-list',{attrs:{"loading":_vm.loading,"schedulers":_vm.schedulers,"machineActiveScheduler":_vm.machine.machine.activeScheduler === 'play',"can-edit-machine":_vm.canProgramMachine,"selected":_vm.currentProgramId},on:{"change_selection":function (id) {
            this$1.currentProgramId = id;
          }}})],1),_c('v-divider',{staticClass:"mt-3",staticStyle:{"border-color":"#707173"},attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"8"}},[_c('program-details',{attrs:{"program":_vm.getSelectedProgram(),"machine":_vm.machine,"can-edit-program":_vm.canProgramMachine},on:{"refetch-programs":function (id) {
            _vm.initializeDataOnDeletion(id);
          },"refetch-program":function (id) {
            _vm.initializeData(id);
          }}})],1)],1),_c('add-program-dialog',{ref:"addProgramDialog",attrs:{"machine":_vm.machine},on:{"refetch-programs":function (id) {
        _vm.initializeData(id);
      }}}),_vm._v(" } ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }