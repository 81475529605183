























































































import Vue, { PropType } from "vue";
import { IViewDetailsMachines } from "@iot-uta-devices/iot-uta-devices-interfaces";
import UneditableSwitch from "@/globalComponents/UneditableSwitch.vue";
import { extend, setInteractionMode } from "vee-validate";
import { max_value, min_value, required } from "vee-validate/dist/rules";
import { putMachineParams } from "@/api/MachinesAPI";

type Sensor = {
  readonly min: number;
  readonly max: number;
  readonly title: string;
  readonly min_label: string;
  readonly max_label: string;
  readonly status: boolean;
  readonly unit: string;
};

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Il valore non può essere vuoto.",
});

extend("min_value", {
  ...min_value,
  message: "Il valore deve essere maggiore di {min}.",
});

extend("max_value", {
  ...max_value,
  message: "Il valore deve essere minore di {max}.",
});

export default Vue.extend({
  name: "MachineConfigurationSection",
  components: { UneditableSwitch },
  props: {
    machine: {
      type: Object as PropType<IViewDetailsMachines>,
      required: true,
    },
    canEditMachine: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    loading: false,

    data: {
      temperature_sensor: {} as unknown as Sensor,

      heater_sensor: {} as unknown as Sensor,

      pressure_sensor: {} as unknown as Sensor,

      pressure_transducer: {} as unknown as Sensor,
    },
  }),

  mounted() {
    this.initializeData();
  },

  watch: {
    machine: function () {
      this.initializeData();
    },
  },

  methods: {
    initializeData: function () {
      this.data.temperature_sensor = {
        title: "Sensore temperatura",
        status: this.machine.machine.hardware.temp_sensor,
        min: this.machine.machine.hardware.temp_min,
        max: this.machine.machine.hardware.temp_max,
        min_label: "Temperatura minima",
        max_label: "Temperatura massima",
        unit: "°C",
      };

      this.data.pressure_sensor = {
        title: "Sensore pressione",
        status: this.machine.machine.hardware.press_sensor,
        min: this.machine.machine.hardware.press_min,
        max: this.machine.machine.hardware.press_max,
        min_label: "Pressione minima",
        max_label: "Pressione massima",
        unit: "bar",
      };

      this.data.heater_sensor = {
        title: "Scaldiglia",
        status: this.machine.machine.hardware.heater_sensor,
        min: this.machine.machine.hardware.heater_temp_on,
        max: this.machine.machine.hardware.heater_temp_off,
        min_label: "Temperatura di attivazione",
        max_label: "Temperatura di spegnimento",
        unit: "°C",
      };

      this.data.pressure_transducer = {
        title: "Trasduttore pressione",
        status: this.machine.machine.hardware.press_sensor,
        min: this.machine.machine.hardware.press_low_volt,
        max: this.machine.machine.hardware.press_high_volt,
        min_label: "Voltaggio a 0 bar",
        max_label: "Voltaggio a 10 bar",
        unit: "mV",
      };
    },

    // ToDo: vedere se esiste una soluzione migliore, utilizzando v-slot={ invalid } su validation-observer l'app crasha.
    isFormInvalid: function () {
      return (
        //@ts-expect-error wrong type
        this.$refs.observer?.flags?.invalid || !this.hasConfigurationChanged()
      );
    },

    submitConfiguration: async function () {
      // @ts-expect-error wrong type
      const valid = await this.$refs.observer.validate();

      if (valid && this.hasConfigurationChanged()) {
        this.loading = true;
        putMachineParams(this.machine.machine.id, {
          temp_max: this.data.temperature_sensor.max,
          temp_min: this.data.temperature_sensor.min,
          press_low_volt: this.data.pressure_transducer.min,
          press_high_volt: this.data.pressure_transducer.max,
          press_max: this.data.pressure_sensor.max,
          press_min: this.data.pressure_sensor.min,
          heater_temp_on: this.data.heater_sensor.min,
          heater_temp_off: this.data.heater_sensor.max,
        }).finally(() => {
          this.$emit("refetch-machine");
          this.loading = false;
        });
      }
    },

    hasConfigurationChanged: function () {
      return (
        this.machine.machine.hardware.heater_temp_on !==
          this.data.heater_sensor.min ||
        this.machine.machine.hardware.heater_temp_off !==
          this.data.heater_sensor.max ||
        this.machine.machine.hardware.temp_min !==
          this.data.temperature_sensor.min ||
        this.machine.machine.hardware.temp_max !==
          this.data.temperature_sensor.max ||
        this.machine.machine.hardware.press_low_volt !==
          this.data.pressure_transducer.min ||
        this.machine.machine.hardware.press_high_volt !==
          this.data.pressure_transducer.max ||
        this.machine.machine.hardware.press_min !==
          this.data.pressure_sensor.min ||
        this.machine.machine.hardware.press_max !==
          this.data.pressure_sensor.max
      );
    },
  },
});
