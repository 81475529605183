



























import Vue from "vue";
import MachineEventSection from "@/components/MachineEventSection.vue";
import MachineTelemetrySection from "@/components/MachineTelemetrySection.vue";
import MachineErrorSection from "@/components/MachineErrorSection.vue";
import MachineErrorResolutionSection from "@/components/MachineErrorResolutionSection.vue";

export default Vue.extend({
  name: "MachineEventsCard",
  components: {
    MachineErrorResolutionSection,
    MachineErrorSection,
    MachineTelemetrySection,
    MachineEventSection
  },

  props: {
    canProgramMachine: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    selectedCard: 0,
    updateKey: 0,
  }),
  created() {
    // @ts-expect-error ts(2339)
    this.$bus.$on("update-detail-card-content", () => {
      this.updateKey++;
    });
  },
});
