var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('components-card-container',[(!_vm.loading && !_vm.error)?_c('v-layout',{staticClass:"fill-height grid"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/machines"}},[_c('p',{staticClass:"machine-code"},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v("fa fa-arrow-left")]),_vm._v(" "+_vm._s(_vm.machine.machine.device_code)+" ")],1)])],1),_c('v-col',{staticClass:"mb-auto",staticStyle:{"text-align":"right"},attrs:{"cols":"10"}},[_c('action-button',{staticClass:"mx-2",attrs:{"text":_vm.getPlayPauseButtonText,"icon":_vm.getPlayPauseButtonIcon,"color":_vm.getPlayPauseButtonColor,"loading":_vm.activateOrDeactivateSchedulingLoading,"is-disabled":!_vm.machine ||
            !_vm.canProgramMachine() ||
            _vm.activateOrDeactivateSchedulingLoading,"on-click":function () {
              _vm.handleActivateOrDeactivateScheduling(this$1.getActionType);
            }}}),_c('action-button',{staticClass:"mx-2",attrs:{"color":"success","text":"Invia Comando","icon":"fas fa-wifi","is-disabled":!_vm.machine || !_vm.canProgramMachine(),"on-click":function () {
              _vm.handleActivateSendCommandDialog();
            }}}),_c('action-button',{staticClass:"mx-2",attrs:{"color":"error","text":"Stop","icon":"fas fa-hand-paper","is-disabled":!_vm.machine || !_vm.canProgramMachine() || _vm.stopLoading,"loading":_vm.stopLoading,"on-click":function () {
              _vm.handleStopMachine();
            }}}),_c('action-button',{staticClass:"mx-2",attrs:{"color":"error","text":"Elimina Macchina","icon":"fas fa-trash","is-disabled":!_vm.machine || !_vm.canEditMachine(),"on-click":function () {
              _vm.handleActivateDeleteMachineDialog();
            }}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',[_vm._v("Eventi")]),(_vm.canProgramMachine())?_c('v-tab',[_vm._v(" Programmazione ")]):_vm._e(),_c('v-tab',[_vm._v("Dettaglio")]),(_vm.canViewUsers())?_c('v-tab',[_vm._v("Utenti")]):_vm._e()],1)],1),_c('v-row',{staticClass:"fill-height flex-wrap flex-column",attrs:{"no-gutters":""}},[(_vm.currentTab === 0)?_c('machine-events-card',{staticClass:"fill-height",attrs:{"can-program-machine":_vm.canProgramMachine() || false}}):_vm._e(),(_vm.canProgramMachine() && _vm.currentTab === 1)?_c('machine-programming-card',{key:_vm.reloadKey,attrs:{"can-program-machine":_vm.canProgramMachine() || false,"machine":this.machine}}):_vm._e(),(
          (_vm.canProgramMachine() && _vm.currentTab === 2) ||
          (!_vm.canProgramMachine() && _vm.currentTab === 1)
        )?_c('machine-details-card',{key:_vm.reloadKey,staticClass:"fill-height",attrs:{"machine":this.machine,"can-edit-machine":_vm.canEditMachine() || false,"can-program-machine":_vm.canProgramMachine() || false},on:{"refetch-machine":_vm.fetchMachine}}):_vm._e(),(_vm.canViewUsers() && _vm.currentTab === 3)?_c('machine-users-card',{key:_vm.reloadKey,staticClass:"fill-height",attrs:{"machine":this.machine}}):_vm._e()],1)],1):_vm._e(),(_vm.loading)?_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{staticClass:"grid"},[_c('v-skeleton-loader',{attrs:{"loading":true,"type":"card-heading"}}),_c('v-row',{staticClass:"my-auto ml-3"},_vm._l((3),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"loading":true,"type":"chip"}})}),1),_c('v-skeleton-loader',{staticClass:"fill-height",attrs:{"loading":true,"type":"image","height":"100%"}})],1)],1):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(this.error.message)+" ")]):_vm._e(),(_vm.machine)?_c('send-command-dialog',{ref:"sendCommandDialog",attrs:{"machine":this.machine}}):_vm._e(),(_vm.machine)?_c('delete-machine-dialog',{ref:"deleteMachineDialog"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }