




























































import Vue from "vue";
import DetailCard from "./DetailCard.vue";
import { EndButtonType } from "@/types/Client";
import { getErrors } from "@/api/EventsAPI";
import { ErrorInfo } from "@/types/ErrorInfo";
import EventErrorDialog from "@/components/EventErrorDialog.vue";
import { getSortByMap } from "@/utils/PaginationHelper";

export default Vue.extend({
  name: "MachineErrorSection",
  components: { EventErrorDialog, DetailCard },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    dataTableHeight: 0,
    selectedItem: null as ErrorInfo | null,
    error: "",
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["date"],
      sortDesc: [true]
    },
    footerOptions: {
      itemsPerPageOptions: [25, 50, 100],
      itemsPerPageText: "Righe per pagina",
      pageText: "{0}-{1} di {2}"
    },
    totalEvents: 0,
    errorEvents: [] as Array<ErrorInfo>,
    headers: [
      {
        text: "Data e ora",
        align: "start",
        value: "date",
      },
      {
        text: "Urgenza",
        align: "start",
        value: "severity",
      },
      {
        text: "Categoria",
        align: "start",
        value: "category",
      },
      {
        text: "Messaggio",
        align: "start",
        value: "message",
      },
      {
        text: "Potenza WI FI",
        align: "start",
        value: "wifi_power",
      },
      {
        text: "Temperatura",
        align: "start",
        value: "temperature",
      },
      {
        text: "Pressione",
        align: "start",
        value: "pressure",
      },
    ],

    endButtons: [] as Array<EndButtonType>,
  }),

  mounted() {
    this.endButtons = [
      {
        icon: "fas fa-chevron-left",
        text: "Telemetria",
        action: () => {
          this.$emit("input", 1);
        },
      },
      {
        icon: "fas fa-chevron-right",
        text: "Risoluzione errori",
        action: () => {
          this.$emit("input", 3);
        },
      },
    ];
  },
  watch: {
      options: {
        handler () {
          this.loadErrorEvents()
        },
        deep: true,
      },
    },
  methods: {
    loadErrorEvents(): void {
      this.error = "";
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      getErrors(this.$route.params.machineID, itemsPerPage, itemsPerPage*(page-1), getSortByMap(sortBy[0]), sortDesc[0] === true ? "desc" : "asc")
        .then((response) => {
          this.totalEvents = response.totalEvents;
          this.errorEvents = response.events.map((err) => ({
            date: new Date(err.publish_time),
            severity: err.severity,
            category: err.category,
            message: err.message,
            wifi_power: `${err.wifi} dB`,
            temperature: `${!isNaN(parseInt(err.telemetry.temperature, 10)) ? err.telemetry.temperature + '°C' : 'Nessuna informazione'}`,
            pressure: `${!isNaN(parseInt(err.telemetry.pressure, 10)) ? err.telemetry.pressure + ' bar' : 'Nessuna informazione'}`,
          }));
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateDataTableHeight(): void {
      this.dataTableHeight = window.innerHeight - 345;
    },

    openDetailDialog(item: ErrorInfo) {
      this.selectedItem = item;
      //@ts-expect-error wrong type
      this.$refs.detailDialog.activateDialog();
    },
  },
});
