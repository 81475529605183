const normalizeCron = (cron: Array<string>): Array<string> => {
  cron[0] = cron[0].replace("*/", "0/");
  if (cron[0] === "0/1") {
    cron[0] = "*";
  }

  cron[1] = cron[1].replace("*/", "0/");
  if (cron[1] === "0/1") {
    cron[1] = "*";
  }

  cron[2] = cron[2].replace("*/", "1/");
  if (cron[2] === "1/1") {
    cron[2] = "*";
  }

  cron[3] = cron[3].replace("*/", "1/");
  if (cron[3] === "1/1") {
    cron[3] = "*";
  }

  cron[4] = cron[4].replace("*/", "1/");
  if (cron[4] === "1/1") {
    cron[4] = "*";
  }

  return cron;
};

const splitCronFieldIntoDeclarations = (cronField: string): Array<string> => {
  return cronField.split(",");
};

const splitCronFieldIntoIntervalsAndSkip = (
  cronField: string
): Array<string> => {
  return cronField.split("/");
};

// Non considera il caso "*/1" perché trasformato in "*".
const getCronValues = (
  cronField: string,
  maxValue: number,
  trasformatorFunction?: (value: string) => number
): Array<number> | string => {
  if (cronField === "*") {
    return "*";
  }

  // Splits the cron in the various declarations separated by commas (,)
  const splitHours = splitCronFieldIntoDeclarations(cronField);

  const parsedFields = [] as Array<number>;

  splitHours.forEach((splitHour) => {
    // Splits each declaration into its values and the eventual skip value (eventually declared after the "/")
    const valueAndSkip = splitCronFieldIntoIntervalsAndSkip(splitHour);

    // Splits the values of the declaration into the eventual intervals if they are declared as "a-b" and parses each to an int
    const interval = valueAndSkip[0].split("-").map((value) => {
      if (trasformatorFunction) {
        // Transforms the value in an integer if it has a special declaration (eg: "WED" -> 3; "DEC" -> 12)
        return trasformatorFunction(value);
      } else {
        return parseInt(value);
      }
    });

    // If there is not an interval between the values and a skip parameter was not declared the single value is returned
    if (interval.length === 1 && valueAndSkip.length === 1) {
      parsedFields.push(interval[0]);
    } else {
      // If the declaration didn't contain a skip value, the skip value is defaulted to the value "1".
      const skipValue =
        valueAndSkip.length === 2 ? parseInt(valueAndSkip[1]) || 1 : 1;

      const firstValue = interval[0];

      // The second value of the interval is initialized as the max value available if it wasn't decleared before.
      const secondValue = interval.length === 2 ? interval[1] : maxValue;

      for (let i = firstValue; i <= secondValue; i += skipValue) {
        parsedFields.push(i);
      }
    }
  });

  return parsedFields;
};

// Non considera il caso "*/1" perché già trasformato in "*".
const getTimes = (minutesCron: string, hoursCron: string): string => {
  const parsedMinutes = getCronValues(minutesCron, 59);

  const parsedHours = getCronValues(hoursCron, 23);

  if (typeof parsedMinutes === "string" && typeof parsedHours === "string") {
    return "Ogni ora ad ogni minuto";
  } else if (
    typeof parsedMinutes === "string" &&
    parsedHours instanceof Array
  ) {
    const joinedHours = parsedHours
      .map((hour) =>
        hour.toLocaleString(undefined, { minimumIntegerDigits: 2 })
      )
      .join("; ");
    return `Alle ore ${joinedHours} ad ogni minuto`;
  } else if (
    typeof parsedHours === "string" &&
    parsedMinutes instanceof Array
  ) {
    const joinedMinutes = parsedMinutes
      .map((min) => min.toLocaleString(undefined, { minimumIntegerDigits: 2 }))
      .join("; ");
    return `Ogni ora ai minuti ${joinedMinutes}`;
  } else {
    const formattedMinutes = (parsedMinutes as Array<number>).map((min) =>
      min.toLocaleString(undefined, { minimumIntegerDigits: 2 })
    );
    const formattedHours = (parsedHours as Array<number>).map((h) =>
      h.toLocaleString(undefined, { minimumIntegerDigits: 2 })
    );

    const formattedHoursList = formattedHours.reduce((array, currentHour) => {
      return array.concat(
        formattedMinutes.map((minute) => `${currentHour}:${minute}`)
      );
    }, [] as Array<string>);

    return formattedHoursList.join("; ");
  }
};

// Non considera il caso "*/1" perché già trasformato in "*".
const getMonths = (monthsCron: string): string => {
  interface IObjectKeys {
    [key: string]: number;
  }

  const ASSOCIATIVE_MONTHS = [
    "",
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  const MONTHS_VALUES: IObjectKeys = {
    JAN: 1,
    FEB: 2,
    MAR: 3,
    APR: 4,
    MAY: 5,
    JUN: 6,
    JUL: 7,
    AUG: 8,
    SEP: 9,
    OCT: 10,
    NOV: 11,
    DEC: 12,
  };

  const transformationFunction = (value: string): number => {
    if (MONTHS_VALUES[value]) {
      return MONTHS_VALUES[value];
    } else {
      return parseInt(value);
    }
  };

  const parsedMonths = getCronValues(monthsCron, 12, transformationFunction);

  if (typeof parsedMonths === "string") {
    return "Ogni mese";
  } else {
    return parsedMonths.map((month) => ASSOCIATIVE_MONTHS[month]).join("; ");
  }
};

// Non considera il caso "*/1" perché già trasformato in "*".
const getDaysOfTheMonth = (daysOfTheMonthCron: string): string => {
  const parsedDaysOfTheMonth = getCronValues(daysOfTheMonthCron, 31);

  if (typeof parsedDaysOfTheMonth === "string") {
    return "Ogni mese.";
  } else {
    return parsedDaysOfTheMonth.join("; ");
  }
};

// Non considera il caso "*/1" perché già trasformato in "*".
const getDaysOfTheWeek = (daysOfTheWeekCron: string): Array<number> => {
  interface IObjectKeys {
    [key: string]: number;
  }

  const DAYS_VALUES: IObjectKeys = {
    0: 7,
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRY: 5,
    SAT: 6,
    SUN: 7,
  };

  const transformationFunction = (value: string): number => {
    if (DAYS_VALUES[value]) {
      return DAYS_VALUES[value];
    } else {
      return parseInt(value);
    }
  };

  const parsedDaysOfTheWeek = getCronValues(
    daysOfTheWeekCron,
    7,
    transformationFunction
  );

  if (typeof parsedDaysOfTheWeek === "string") {
    return [1, 2, 3, 4, 5, 6, 7];
  } else {
    return parsedDaysOfTheWeek;
  }
};

export type ParsedCron = {
  readonly times: string;
  readonly months: string;
} & (
  | { readonly daysOfTheMonth: string }
  | { readonly daysOfTheWeek: Array<number> }
);

export const getParsedCron = (cron: string): ParsedCron => {
  const splitCron = cron.split(" ");

  splitCron[2] = splitCron[2].replace("?", "*");

  splitCron[4] = splitCron[4].replace("?", "*");

  const normalizedCron = normalizeCron(splitCron);

  const minutes = normalizedCron[0];
  const hours = normalizedCron[1];
  const daysOfTheMonth = normalizedCron[2];
  const months = normalizedCron[3];
  const daysOfTheWeek = normalizedCron[4];

  if (daysOfTheMonth !== "*") {
    return {
      times: getTimes(minutes, hours),
      months: getMonths(months),
      daysOfTheMonth: getDaysOfTheMonth(daysOfTheMonth),
    };
  } else {
    return {
      times: getTimes(minutes, hours),
      months: getMonths(months),
      daysOfTheWeek: getDaysOfTheWeek(daysOfTheWeek),
    };
  }
};
